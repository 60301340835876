<template>
    <basic-layout class="ma-4">
        <v-container class="fill-height" fluid >
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <app-bar :title="title"/>
                    <div class="">
                        <v-row v-if="!user" align="center">
                            <v-col>
                                <div class="font-weight-light primary--text text-center">
                                    <div class="text-center" align="center" justify="center">
                                        <v-progress-circular :size="50" :width="5" color="green" indeterminate></v-progress-circular>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-else class="my-0" align="center" justify="center"  >
                            <v-col>
                                <div class="text-center" align="center" justify="center">
                                    <img width="100"   alt="Vue logo" src="../../assets/logo.png">
                                </div>
                                <v-layout column v-if="!isEdit" >
                                    <v-alert v-if="alert.text" dense text :type="alert.type">
                                        {{alert.text}}
                                    </v-alert>
                                    <span class="caption mt-2 font-italic mt-3 grey--text" >Name</span>
                                    <span class="subheading mt-2 font-weight-medium" >{{user.firstname}}  {{user.middlename}}  {{user.lastname}}</span>
                                    <span class="caption mt-2 font-italic mt-3 grey--text" >Employee ID</span>
                                    <span class="subheading mt-2 font-weight-medium" >{{user.employee_id}}</span>
                                    <span class="caption mt-2 font-italic mt-3 grey--text" >Position</span>
                                    <div v-if="user">
                                        <span class="subheading mt-2 font-weight-medium green--text" v-if="user.designation">{{user.designation}}</span>
                                        <span class="subheading mt-2 font-weight-medium font-italic" v-else>loading...</span>
                                    </div>
                                    <span class="subheading mt-2 font-weight-medium grey--text" v-else>Not Available</span>
                                    <v-btn v-if="!isEdit" block  class="mt-5"  color="primary" @click.prevent="edit">Edit Profile</v-btn>
                                </v-layout>
                                <div v-if="isEdit" class="mt-6">
                                    <v-form v-model="valid" ref="form" >
                                        <v-text-field   :rules="rules.fieldRules" label="First Name" v-model="user.firstname"  prepend-icon="mdi-account" type="text"/>
                                        <v-text-field   label="Middle Name"  v-model="user.middlename"  prepend-icon="mdi-account" type="text"/>
                                        <v-text-field :rules="rules.fieldRules" label="Last Name"  v-model="user.lastname"  prepend-icon="mdi-account" type="text"/>
                                        <v-text-field  v-on:blur="emailCheck"  :rules="rules.emailRules" label="Email"  v-model="user.email"  prepend-icon="mdi-email" type="text"/>
<!--                                        <v-text-field  :rules="rules.idRules" v-on:blur="idCheck" v-mask="'AAA-####'" label="Employee ID No. (e.g. MMC-999)" v-model="user.employee_id"  prepend-icon="mdi-credit-card" type="text"/>-->
                                        <v-btn  block class="mt-5" :loading="updating" :disabled="updating" color="primary" @click.prevent="update">
                                            Update Profile
                                            <template v-slot:loader>
                                                <span>Updating. Please wait...</span>
                                            </template>
                                        </v-btn>
                                    </v-form>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </basic-layout>
</template>

<script>
    import user from "../../api/user";
    export default {
        name: "Profile",
        data: () => ({
            title: 'My Profile',
            valid: false,
            e1 : false,
            e2: false,
            isEdit: false,
            hasEmailError: false,
            hasIdError: false,
            updating: false,
            submitted: false,
            form: {},
            rules:{
                passwordRules: [
                    v => !!v || 'Password is required',
                    v => (v && v.length >= 5) || 'Password must have at least 8  characters',
                    v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
                    v => /(?=.*\d)/.test(v) || 'Must have one number',
                    v => /([!@$%_])/.test(v) || 'Must have one special character'
                ],
                fieldRules: [
                    v => !!v || 'This field is required',
                ],
                emailRules: [
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Please provide a valid email',
                    v => !!v || 'This field is required',
                ],
                idRules: [
                    v => !!v || 'This field is required',
                ]
            },
            errors: null,
        }),
        methods: {
            edit(){
                this.isEdit = true;
            },
            emailCheck(){
                this.hasEmailError = false;
                if(this.submitted){
                    this.$refs.form.validate();
                }
            },
            idCheck(){
                this.hasIdError = false;
                if(this.submitted){
                    this.$refs.form.validate();
                }
            },
            update(){
                if(this.$refs.form.validate()){
                    this.updating = true;
                    this.submitted = true;
                    user.update(this.user)
                        .then(response =>{
                            this.updating = false;
                            this.$store.dispatch('setAlert',response.data);
                            this.isEdit = false;
                        })
                        .catch(error => {
                            this.updating = false;
                            this.errors = error.response.data.errors;
                            this.$store.dispatch('setAlert',{text: "Error in updating your profile.",type: 'error'});
                            if( this.errors.employee_id){
                                this.hasIdError = true;
                                this.$refs.form.validate();
                                this.$store.dispatch('setAlert',{text: this.errors.employee_id[0],type: 'error'});
                            }
                            if( this.errors.email){
                                this.hasEmailError = true;
                                this.$refs.form.validate();
                                this.$store.dispatch('setAlert',{text: this.errors.email[0],type: 'error'});
                            }
                        });
                }

            }
        },
        created() {
            // this.form = this.user;
        },
        computed: {
            user(){
                return this.$store.getters.getCurrentUser;
            },
            alert(){
                return this.$store.getters.getAlert;
            }
        }
    }
</script>

<style scoped>

</style>